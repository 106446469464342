.App {
}

.menu-bar {
  width: 100%;
  height: 75px;
  background-color: black;
  color: white;
  text-align: center;
}

.menu-bar-button {
  display: inline-block;
  margin: 20px;
  width: 10%;
  height: 50%;
}

.menu-bar-image {
  position: absolute;
  left: 25px;
  top: 0px;
  width: 75px;
  height: 75px;
  background-color: black;
  font-size: 0px;
}

.menu-bar-image img{
  width: 100%;
  height: 100%;
  font-size: 0px;
}


.menu-bar h1{
  line-height: 40px;
  font-size: 20px;
  margin: 0px;
  vertical-align: middle;
  color: white;
}

.menu-bar h2{
  font-size: 30px;
  margin: 0px;
}

/* The hero image */
.hero {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  height: 93vh;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-image {
  width: 100%;
  height: 100%;
}

.hero-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 25px;
		padding-left: 75px;
		padding-right: 75px;
}

.hero-text img{
  width: 30%;
  height: 30%;
}

.hero-text h1{
  font-size: 100px;
}

.hero-text p{
  font-size: 25px;
}

.about {
  width: 100%;
  background-color: black;
  display: flex;
  text-align: center;
  position: static;
  top: 50%;
  color: white;
  justify-content: center;
}


.footer {
  width: 100%;
  background-color: black;
  display: flex;
  text-align: center;
  position: static;
  top: 50%;
  color: white;
  justify-content: center;
}


.about .card {
  position: relative;
  display: inline-block;
  width: 33%;
  padding: 25px;
}

.about .card p{
  font-size: 25px;
}

.info {
  display: block;
  width: 100%;
  background-color: black;
  text-align: center;
  position: static;
  top: 50%;
  color: white;
}

.info .panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
}


.info img{
  display: inline-block;
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.info .panel .wrapper{
  display: inline-block;
  width: 50%;
  height: 100%;
}

.info .card{
  display: inline-block;
  width: 80%;
  height: 100%;
  margin: 10px;
  overflow: hidden;
}

.info .card img{
  width: 0px;
  height: 0px;
}


.info .card h1{
  font-size: 3vw;
}
.info .card p{
  font-size: 30px;
}

.menu {

  width: 100%;
  background-color: black;
  display: inline-block;
  text-align: center;
  position: static;
  top: 50%;
  color: white;
}

.menu-items-container {
  font-size: 0px;
}

.menu-item {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 700px;
  margin: 0px;
  padding: 0px;
}

.menu-item-hover {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  background-color: rgba(0, 0, 0, 0.2);
}

.menu-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px;
  padding: 0px;
  font-size: 0px;
}

.footer {
  justify-content: center;
  display: inline-block;
}

.footer .maps-panel{
  width: 100%;
  height: 500px;
  display: flex;
}

.footer .maps{
  width: 50%;
}

.footer .maps iframe{
  height: 100%;
  width: 100%;
}

.footer .maps-address{
  width: 50%;
  height: 100%;
  text-align: center;
  margin: auto;
  overflow: hidden;
}

.footer .maps-address h1{
  font-size: 60px;
}

.footer .maps-address ul{
  list-style: none;
}

.footer .maps-address li{
  list-style: none;
  margin: auto;
  font-size: 30px;
}


.footer-icons {
  font-size: 0px;
  width: 40%;
  height: auto;
  display: inline-block;
  padding: 20px;
  justify-content: center;
}

.footer-icons img{
  font-size: 0px;
  width: 20%;
  padding: 5px;
}

/***************************************************************/
/* Mobile view */
/***************************************************************/

@media (max-width: 575.98px) {  

.App {
  margin: 0px;
}

.body {
  margin: 0px;
}

.menu-bar {
  left: 100px;
  top: 0px;
  width: 100%;
  height: 75px;
  background-color: black;
  color: white;
  text-align: center;
}

.menu-bar-button {
  position: relative; 
  margin: 0px;
  left: 36px;
  width: 25%;
  height: 40%;
  padding: 2px;
}

.menu-bar-image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 75px;
  height: 75px;
  background-color: black;
  font-size: 0px;
}

.menu-bar-image img{
  width: 100%;
  height: 100%;
  font-size: 0px;
}


.menu-bar h1{
  line-height: 35px;
  font-size: 15px;
  margin: 0px;
  vertical-align: middle;
  color: white;
}

.menu-bar h2{
  font-size: 30px;
  margin: 0px;
}
/* The hero image */
.hero {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  height: 50vh;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-image {
  width: 100%;
  height: 100%;
}

.hero-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.9;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.hero-text img{
  width: 40%;
  height: 40%;
}

.hero-text h1{
  font-size: 3em;
}

.hero-text h2{
  font-size: 1em;
}

.about {
  width: 100%;
  height: 100%;
  background-color: black;
  display: inline-block;
  color: white;
}

.about .card {
  position: relative;
  width: 80%;
  height: 50%;
}

.about .card p{
  font-size: 25px;
}


.footer {
  width: 100%;
  background-color: black;
  display: flex;
  text-align: center;
  position: static;
  top: 50%;
  color: white;
  justify-content: center;
}

.info {
  display: inline-block;
  width: 100%;
  background-color: black;
  text-align: center;
  color: white;
  font-size: 0px;
  padding: 0px;
  margin: 0px;
}

.info .panel {
  display: inline-block;
  align-items: center;
  width: 100%;
  height: 660px;
  margin: 0px;
  padding: 0px;
  font-size: 0px;
}


.info img{
  display: inline-block;
  width: 100%;
  height: 50%;
  object-fit: cover;
  margin: 0px;
  padding: 0px;
}

.info .panel .wrapper{
  display: inline-block;
  width: 90%;
  height: 50%;
  font-size: 90px;
  margin: 0px;
}

.info .card{
  display: inline;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  vertical-align: middle;
  font-size: 0px;
}

.info .card img{
  display: none;
  font-size: 0px;
  padding: 0px;
}


.info .card h1{
  display: inline-block;
  font-size: 6vw;
  margin: 0px;
}

.info .card p{
  font-size: 4vw;
}

.menu {
  position: inline-block;
  margin: 0px;
  
}

.menu-items-container {
  font-size: 0px;
  padding: 0px;
  margin: 0px;
}

.menu-item {
  position: relative;
  width: 100%;
  height: 300px;
  margin: 0px;
  padding: 0px;
}

.menu-item-hover {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 5vw;
  background-color: rgba(0, 0, 0, 0.2);
}

.menu-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px;
  padding: 0px;
  font-size: 0px;
}

.footer {
  justify-content: center;
  display: inline-block;
}

.footer .maps-panel{
  width: 100%;
  height: 422px;
  display: inline-block;
}

.footer .maps{
  width: 100%;
  height: 100%;
}

.footer .maps iframe{
  height: 100%;
  width: 100%;
}

.footer .maps-address{
  width: 100%;
  height: 422px;
  text-align: center;
  overflow: hidden;
}

.footer .maps-address h1{
  padding: 5px;
  font-size: 10vw;
}


.footer .maps-address li{
  padding: 5px;
  list-style: none;
  font-size: 5vw;
}


.footer-icons {
  font-size: 0px;
  width: 80%;
  height: auto;
  display: inline-block;
  justify-content: center;
}

.footer-icons img{
  font-size: 0px;
  width: 20%;
  padding: 5px;
}

}
